.PurgeTracks {
    &__subtext {
        color: #ff1db7 !important;
    }

    &__list-item {
        padding-right: 100px !important;

        &:hover {
            background-color: rgba(255, 29, 183, 0.04) !important;
        }
    }

    &__vote-btn {
        color: #ff1db7;

        &:hover {
            background-color: rgba(255, 29, 183, 0.04) !important;
        }
    }

    &__track-name {
        span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 100%;
        }
    }

    &__close-btn {
        position: absolute;
        top: 0;
        right: 0;
        color: #ff1db7;

        &:hover {
            background-color: rgba(255, 29, 183, 0.04);
        }
    }
}

@media (max-width: 700px) {
    .PurgeTracks {
        &__content {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
}
