.UserVotes {
    &__track-name-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 16px;
    }

    &__track-img {
        width: 40px;
        height: 40px;
        margin-right: 1rem;
    }
}
