.PlaylistStatistics {
  &__chart-wrapper {
    padding: 1%;
    width: 96%;
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 10px;
  }

  &__main.PlaylistStatistics__chart-wrapper {
    height: 300px;
  }

  &__sub.PlaylistStatistics__chart-wrapper {
    //width: 29%;
    width: 40%;
    height: 250px;
    margin-top: 1rem;
  }

  &__first-sub-stat {
    margin-right: 1rem;
  }

  &__title {
    color: #ff1db7;
    font-size: 2rem;
    margin: 1rem;
  }

  &__sub-info {
    color: #ff1db7;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 10px;

    &--stat {
      color: antiquewhite;
    }
  }

  &__container {
    width: 100%;
  }

  @media (max-width: 700px) {
    &__sub.PlaylistStatistics__chart-wrapper {
      width: 96%;
      margin-top: 0.5rem;
    }

    &__first-sub-stat {
      margin-right: 0;
    }

    &__title {
      font-size: 1.5rem;
    }
  }
}
