.Search {
  &__search-btn {
    color: #ff1db7;
    position: fixed;
    right: 36px;
    top: 36px;
    margin: 0;
    padding: 0;
  }

  &__search-container {
    margin: 60px 0 20px 0;
    padding: 2px 4px;
    width: 300px;
  }

  &__search-input {
    display: flex;
  }

  &__table-container {
    width: 80%;
    height: 80%;
    overflow: auto;
  }

  &__no-results {
    color: #3a205b;
    margin: 0 0 5px 10px;
    text-align: left;
    font-size: 14px;
  }

  &__table-header {
    font-weight: bold;
  }

  &__icon-button {
    padding: 10px;
    color: #ff1db7;
  }

  &__table {
    min-width: 650px;
  }

  @media (max-width: 700px) {
    &__search-btn {
      right: 20px;
      top: 20px;
    }

    &__table-container {
      width: 100%;
      height: 100%;
    }
  }
}
