.Playlists {
  &__container {
      display: flex;
      margin-top: 2rem;
      flex-wrap: wrap;
      justify-content: center;
  }

  &__title {
      color: #ff1db7;
      font-size: 3rem;
      margin-bottom: 0;
  }

  &__subtitle {
      color: antiquewhite;
      margin-top: 0;
  }

  &__error {
    color: white;
    align-self: center;
  }

  @media (max-width: 700px) {
    &__container {
      margin-top: 0;
    }

    &__subtitle {
      margin-bottom: 0.5rem;
      font-size: 1.25rem;
    }

    &__title {
      font-size: 1.5rem;
      margin-right: 3rem;
      margin-left: 3rem;
      margin-bottom: 0.5rem;
    }
  }
}
