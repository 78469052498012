.SignIn {
  &__container {
    margin-top: 25%;
  }

  &__button {
    font-size: 2rem;
    border-radius: 10px;
    background-color: #3a205b;
    border: 1px solid antiquewhite;
    color: #FFFFFF;
    text-align: center;
    padding: 20px;
    transition: all 0.5s;
    cursor: pointer;
    margin: 5px;

    span {
      cursor: pointer;
      display: inline-block;
      position: relative;
      transition: 0.5s;
    }

    &:hover {
      background-color: #2c154a;

      span {
        padding-right: 25px;
      }

      span:after {
        opacity: 1;
        right: 0;
      }
    }

    span:after {
      content: '\00bb';
      position: absolute;
      opacity: 0;
      top: 0;
      right: -20px;
      transition: 0.5s;
    }

    &:focus {
      outline: 0;
    }
  }
}
