.Playlist {
  &__container {
      margin: 1rem;
  }

  &__album-cover {
      height: 275px;
      width: 275px;
      cursor: pointer;
      border:1px solid black;

      &:hover {
          -moz-box-shadow: 0 0 15px #ff1db7;
          -webkit-box-shadow: 0 0 15px #ff1db7;
          box-shadow: 0 0 15px #ff1db7;
      }
  }
}
