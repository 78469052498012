.App {
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-image: url('resources/summervibes-background.jpg');
  background-size: 100% 100%;
  overflow: auto;
}

@media (max-width: 700px) {
  .App {
    background: linear-gradient(#2c154a, #3a205b, #ff1db7);
  }
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
