.LoadingSpinner {
  top: 50%;
  right: 50%;
  position: absolute;
  transform: translate(50%,-50%);

  &--white {
    color: #FFFF !important;
  }
}
