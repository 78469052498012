.BurgerMenu {
  position: fixed;
  left: 20px;
  top: 20px;
  color: #ff1db7;

  &__close-btn {
    color: #ff1db7;
  }

  &__close-btn:hover {
    background-color: rgba(255, 29, 183, 0.04);
  }

  &__list-item {
    color: #ff1db7 !important;

    &-icon {
      color: #ff1db7 !important;
    }
  }

  &__list-item:hover {
    background-color: rgba(255, 29, 183, 0.04) !important;
  }

  &__divider {
    background-color: #ff1db7 !important;
    opacity: 0.5;
  }

  &:hover {
    background-color: rgba(255, 29, 183, 0.04);
  }
}

@media (max-width: 700px) {
  .BurgerMenu {
    left: 10px;
    top: 10px;
  }
}
